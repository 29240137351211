import type { ColumnBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import type { BlockTreeProps } from "../type";
import BlockChildren from "./BlockChildren";

export default function Column(
  props: BlockTreeProps<ColumnBlockObjectResponse>
) {
  const { children } = props;

  return <BlockChildren>{children}</BlockChildren>;
}
