import type { ColumnListBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import type { BlockTreeProps } from "../type";
import BlockChildren from "./BlockChildren";

export default function ColumnList(
  props: BlockTreeProps<ColumnListBlockObjectResponse>
) {
  const { children } = props;

  return (
    <div className="flex gap-4">
      <BlockChildren>{children}</BlockChildren>
    </div>
  );
}
