import { formatPageId, type PageId, type PageMap } from "@mrs/common";
import type { ReactNode} from "react";
import { createContext, useContext, useMemo } from "react";
import type { PageObjectResponse } from "@notionhq/client/build/src/api-endpoints.js";
import { raise } from "@cartographerio/util";

export interface NotionPageContext {
  pageMap: PageMap;
  page: PageObjectResponse;
}

export const NotionPageContext = createContext<NotionPageContext | null>(null);

export function useNotionPageContext(): NotionPageContext {
  return (
    useContext(NotionPageContext) ?? raise(new Error("No Notion page context"))
  );
}

export function useNotionPageMap(): PageMap {
  return useNotionPageContext().pageMap;
}

export function useNotionPage(): PageObjectResponse {
  return useNotionPageContext().page;
}

export function useNotionPageId(): PageId {
  return formatPageId(useNotionPageContext().page.id);
}

export interface NotionPageProviderProps {
  pageMap: PageMap;
  page: PageObjectResponse;
  children: ReactNode;
}

export function NotionPageProvider(props: NotionPageProviderProps) {
  const { pageMap, page, children } = props;
  const value = useMemo(() => ({ pageMap, page }), [page, pageMap]);
  return (
    <NotionPageContext.Provider value={value}>
      {children}
    </NotionPageContext.Provider>
  );
}
