import { checkExhausted } from "@cartographerio/util";
import type { CalloutBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import type { ReactNode } from "react";
import { useMemo } from "react";
import RichText from "../RichText";
import type { BlockTreeProps } from "../type";
import BlockChildren from "./BlockChildren";

export default function Callout(
  props: BlockTreeProps<CalloutBlockObjectResponse>
) {
  const { block, children } = props;

  const icon = useMemo<ReactNode>(() => {
    switch (block.callout.icon?.type) {
      case "emoji":
        return block.callout.icon.emoji;
      case "external":
        return <img src={block.callout.icon.external.url} alt="Callout Icon" />;
      case "file":
        return <img src={block.callout.icon.file.url} alt="Callout Icon" />;
      case null:
      case undefined:
        return null;
      default:
        return checkExhausted(block.callout.icon);
    }
  }, [block.callout.icon]);

  return (
    <div className="bg-zinc-50 rounded-md my-4 py-1">
      <div className="flex flex-row cursor-pointer items-start my-2">
        <div className="flex flex-0 w-8 h-7 justify-center items-center">
          {icon}
        </div>
        <div className="flex-1">
          <RichText richText={block.callout.rich_text} />
        </div>
      </div>
      <div className="pl-8">
        <BlockChildren>{children}</BlockChildren>
      </div>
    </div>
  );
}
