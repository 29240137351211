import { formatBlockId, formatPageId } from "@mrs/common";
import type { FileBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import { useMemo } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { useNotionPageId } from "../NotionPageContext.js";
import type { BlockTreeProps } from "../type";
import BlockChildren from "./BlockChildren";
import Caption from "./Caption";
import { mediaUrl } from "./media";

export default function File(props: BlockTreeProps<FileBlockObjectResponse>) {
  const { block, children } = props;

  const pageId = useNotionPageId();

  const url = useMemo(
    () => mediaUrl(formatPageId(pageId), formatBlockId(block.id), "content"),
    [block.id, pageId]
  );

  return (
    <div>
      <FileLink url={url} label={block.file.name} />
      <Caption richText={block.file.caption} />
      <BlockChildren>{children}</BlockChildren>
    </div>
  );
}

interface FileLinkProps {
  url: string;
  label: string;
}

function FileLink(props: FileLinkProps) {
  const { url, label } = props;

  return (
    <div className="my-4">
      <p className="!my-2">
        <a target="_blank" className="flex" href={url} rel="noreferrer">
          <span className="text-zinc-500">
            <MdOutlineFileDownload className="flex-0 mt-1" size="22" />
          </span>
          <span className="flex-1 min-w-0">{label}</span>
        </a>
      </p>
    </div>
  );
}
