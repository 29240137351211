import { checkExhausted } from "@cartographerio/util";
import type { BlockTree, PageTree } from "@mrs/common";
import type { RichTextItemResponse } from "@notionhq/client/build/src/api-endpoints.js";
import { useMemo, type ReactElement } from "react";
import Heading from "./Heading.js";
import Link from "./Link.js";
import Panel from "./Panel.js";
import Spaced from "./Spaced.js";
import { headingAnchor, headingText } from "./notion/blocks/Heading.js";

export interface TocPanelProps {
  pageTree: PageTree;
}

export function TocPanel(props: TocPanelProps): ReactElement | null {
  const { pageTree } = props;

  const headings = useHeadings(pageTree);

  return headings.length === 0 ? null : (
    <Panel>
      <Spaced spacing="4">
        <Heading level="subsubsection">On This Page</Heading>
        <ul className="flex flex-col gap-2 text-sm">
          {headings.map(({ level, title, anchor }, index) => {
            const className =
              level === 1
                ? undefined
                : level === 2
                  ? "pl-4"
                  : level === 3
                    ? "pl-8"
                    : checkExhausted(level);

            return (
              <li key={index} className={className}>
                <Link.Internal to={anchor}>{title}</Link.Internal>
              </li>
            );
          })}
        </ul>
      </Spaced>
    </Panel>
  );
}

interface HeadingNode {
  title: string;
  anchor: string;
  level: 1 | 2 | 3;
}

function useHeadings(pageTree: PageTree): HeadingNode[] {
  return useMemo(
    () => pageTree.children.flatMap(headings),
    [pageTree.children]
  );
}

function heading(level: number, richText: RichTextItemResponse[]): HeadingNode {
  return {
    level,
    title: headingText(richText),
    anchor: "#" + headingAnchor(richText),
  };
}

function headings(blockTree: BlockTree): HeadingNode[] {
  const { block, children } = blockTree;

  const childHeadings = children == null ? [] : children.flatMap(headings);

  switch (block.type) {
    case "heading_1":
      return [heading(1, block.heading_1.rich_text), ...childHeadings];
    case "heading_2":
      return [heading(2, block.heading_2.rich_text), ...childHeadings];
    case "heading_3":
      return [heading(3, block.heading_3.rich_text), ...childHeadings];
    case "paragraph":
    case "bulleted_list_item":
    case "numbered_list_item":
    case "quote":
    case "to_do":
    case "toggle":
    case "template":
    case "synced_block":
    case "child_page":
    case "child_database":
    case "equation":
    case "code":
    case "callout":
    case "divider":
    case "breadcrumb":
    case "table_of_contents":
    case "column_list":
    case "column":
    case "link_to_page":
    case "table":
    case "table_row":
    case "embed":
    case "bookmark":
    case "image":
    case "video":
    case "pdf":
    case "file":
    case "audio":
    case "link_preview":
    case "unsupported":
      return childHeadings;
    default:
      return checkExhausted(block);
  }
}
