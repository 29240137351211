import type { RichTextItemResponse } from "@notionhq/client/build/src/api-endpoints";
import type { ReactElement } from "react";
import RichText from "../RichText";

interface CaptionProps {
  richText: RichTextItemResponse[];
}

export default function Caption(props: CaptionProps): ReactElement | null {
  const { richText } = props;

  return richText.length > 0 ? (
    <p className="!my-2 text-zinc-400">
      <RichText richText={richText} />
    </p>
  ) : null;
}
