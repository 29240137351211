import { checkExhausted } from "@cartographerio/util";
import type { BlockTree } from "@mrs/common";
import initial from "lodash/initial";
import last from "lodash/last";
import type { ReactNode } from "react";
import { useMemo } from "react";
import Block from "./Block";

interface BlockChildrenProps {
  children: BlockTree[] | undefined;
}

export default function BlockChildren(props: BlockChildrenProps) {
  const { children = [] } = props;

  const groups = useMemo(() => groupChildren(children), [children]);

  return children == null ? null : (
    <>
      {groups.map(group => (
        <BlockChildrenGroup key={group.children[0].block.id} group={group} />
      ))}
    </>
  );
}

interface BlockChildrenGroupProps {
  group: ChildGroup;
}

function BlockChildrenGroup(props: BlockChildrenGroupProps) {
  const {
    group: { type, children },
  } = props;

  const wrapListItem = ({ block, children }: BlockTree): ReactNode => (
    <li key={block.id}>
      <Block block={block}>{children}</Block>
    </li>
  );

  const wrapPlainChild = ({ block, children }: BlockTree): ReactNode => (
    <Block key={block.id} block={block}>
      {children}
    </Block>
  );

  switch (type) {
    case "bulleted_list_item":
      return <ul>{children.map(wrapListItem)}</ul>;
    case "numbered_list_item":
      return <ol>{children.map(wrapListItem)}</ol>;
    case "toggle":
      return <ul className="list-none pl-0">{children.map(wrapPlainChild)}</ul>;
    case "column": {
      let widthClass: string;
      switch (children.length) {
        case 2:
          widthClass = "w-1/2";
          break;
        case 3:
          widthClass = "w-1/3";
          break;
        case 4:
          widthClass = "w-1/4";
          break;
        case 5:
          widthClass = "w-1/5";
          break;
        case 1:
        default:
          widthClass = "w-full";
      }

      return (
        <>
          {children.map((child, index) => (
            <div key={index} className={widthClass}>
              {wrapPlainChild(child)}
            </div>
          ))}
        </>
      );
    }
    case "paragraph":
    case "heading_1":
    case "heading_2":
    case "heading_3":
    case "quote":
    case "to_do":
    case "template":
    case "synced_block":
    case "child_page":
    case "child_database":
    case "equation":
    case "code":
    case "callout":
    case "divider":
    case "breadcrumb":
    case "table_of_contents":
    case "column_list":
    case "link_to_page":
    case "table":
    case "table_row":
    case "embed":
    case "bookmark":
    case "image":
    case "video":
    case "pdf":
    case "file":
    case "audio":
    case "link_preview":
    case "unsupported":
      return <>{children.map(wrapPlainChild)}</>;
    default:
      return checkExhausted(type);
  }
}

type BlockType = BlockTree["block"]["type"];

interface ChildGroup {
  type: BlockType;
  children: BlockTree[];
}

function groupChildren(children: BlockTree[]): ChildGroup[] {
  return children.reduce<ChildGroup[]>((groups, child) => {
    return lastGroupType(groups) !== child.block.type
      ? addNewGroup(groups, child)
      : addToCurrentGroup(groups, child);
  }, []);
}

function addNewGroup(groups: ChildGroup[], child: BlockTree): ChildGroup[] {
  return [...groups, { type: child.block.type, children: [child] }];
}

function addToCurrentGroup(
  groups: ChildGroup[],
  child: BlockTree
): ChildGroup[] {
  const init = initial(groups);
  const curr = last(groups);
  return curr == null
    ? addNewGroup(groups, child)
    : [...init, { ...curr, children: [...curr.children, child] }];
}

function lastGroupType(groups: ChildGroup[]): BlockType | null {
  return groups.length === 0 ? null : groups[groups.length - 1].type;
}
